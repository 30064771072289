import React,{useState} from 'react'
import { Dialog, DialogActions, DialogContent,  IconButton, Tooltip } from "@mui/material";
import { Delete, Restore, Warning } from '@mui/icons-material';


export default function RestoreDialog({id, confirm,children}) {
    const [dialogStatus, setDialogStatus] = useState(false);
    const handleClose = () =>{
        setDialogStatus(false)
    }
    return (
    <>  
        
        <Tooltip title="Restore">
            <IconButton onClick={()=>setDialogStatus(true)} style={{padding:"0px"}}>
                <Restore/>
            </IconButton>
        </Tooltip>
        
        <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
        >

            <DialogContent> <Warning/> {children}</DialogContent>
            <DialogActions>
                <button type="button" onClick={()=>{confirm(id);handleClose()} } className="btn btn-danger">Yes</button>
                <button type="button" onClick={handleClose} className="btn btn-success">No</button>
            </DialogActions>
        </Dialog>
    </>
    )
}
