import { Album, BatteryFull, Campaign, DeviceThermostat, Pause, PinDrop, PlayArrow } from '@mui/icons-material'
import { LinearProgress } from '@mui/material';
import React, {useState, useEffect} from 'react'
import deviceClientServices from '../deviceClientServices';
import deviceTrackServices from '../deviceTrackServices';
import ManageDeviceTrack from './ManageDeviceTrack';

function DeviceControl({deviceState, setDeviceState, publishMessage,deviceId}) {

    const [selectedTrackId,setSelectedTrackId] = useState(null);

    const [deviceTrackList,setDeviceTrackList] = useState(null);
    const getDeviceTrackList = () =>{
        deviceTrackServices.getList(deviceId).then(res=>{
            setDeviceTrackList(res.data);
        })
    }
    useEffect(()=>{
        getDeviceTrackList();
    },[deviceId])

    const updateSelectedTrackId = () =>{
        if(deviceTrackList && deviceTrackList.length > 0 && deviceState){
            //console.log("device state T",deviceState.T);
            //console.log(" deviceTrackList", deviceTrackList);
            if(deviceTrackList[deviceState.T-1]){
                let _selectedTrack = deviceTrackList[deviceState.T-1];
                setSelectedTrackId(_selectedTrack.id)
            } else {
                let _selectedTrack = deviceTrackList[0];
                setSelectedTrackId(_selectedTrack.id);

                let newState = {...deviceState}
                newState["T"]= 0;
            }
            
            console.log(deviceState);
        }
    }

    useEffect(()=>{
        updateSelectedTrackId();
    },[deviceTrackList,deviceState])

    const handleValueChange = (e) =>{
        let newState = {...deviceState}
        newState[e.target.name]= e.target.value;
        setDeviceState(newState);
        /*
        if(e.target.name == "T"){
            let selected = e.target.childNodes[e.target.selectedIndex];
            let trackId =  selected.getAttribute('data-id');
            setSelectedTrackId(trackId);
        }
        */
    }

    const handleButtonValueChange = (key)=>{
        let newState = {...deviceState}

        //if( newState[key] == 0 ){newState[key] = 1}
        //if( newState[key] == 1 ){newState[key] = 0}
        newState[key] = !newState[key];

        console.log("prepaired new state",newState);
        //setDeviceState(newState);

        
        //console.log("-------------------->");
        //console.log("device id",deviceId);
        let payload = {...newState}
        payload.deviceId = deviceId;
        payload.trackId = selectedTrackId;
        //console.log("update this log in db");
        //console.log(payload)
        deviceClientServices.updateLog(payload).then(res=>{
            //console.log(res);
        }).catch(error=>{
            //console.log(error);
        })
        //console.log("<--------------------")
        publishMessage(newState);
        
    }

    

    return (
        <>  
            {/*JSON.stringify(trackList)*/}

            <div className="row">

                <div className="col-md-2 mb-3">
                    <div className="text-danger"><PinDrop/> <span className="fw-bold">{deviceState.Site_ID}</span></div>
                    <div className="text-success"><DeviceThermostat/> <span className="fw-bold">{deviceState.TEMP} &deg;</span></div>
                    <div className="text-primary"><BatteryFull/> <span className="fw-bold">{deviceState.BAT} V</span></div>
                </div>

                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="form-floating">
                                <select className="form-select" value={deviceState.T}
                                    name="T"
                                    onChange={handleValueChange}
                                >
                                    {deviceTrackList?.map((track,i)=>
                                        track.status ===1 && <option key={track.id} value={i+1} > {i+1}. {track.trackTitle}</option>
                                    )}
                                </select>
                                <label htmlFor="floatingSelect">Track {selectedTrackId} </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <span className="">
                                <ManageDeviceTrack 
                                    deviceId={deviceId}
                                    deviceTrackList={deviceTrackList}
                                    getDeviceTrackList={getDeviceTrackList}
                                />
                            </span>
                        </div>
                        <div className="col-md-3">
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" value={deviceState.T_PT} name="T_PT"
                                    onChange={handleValueChange}
                                />
                                <label htmlFor="floatingInput">Track Playtime</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" value={deviceState.H_PT} name="H_PT"
                                    onChange={handleValueChange}
                                />
                                <label htmlFor="floatingInput">Hooter Playtime</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-floating mb-3">
                                <input type="number" className="form-control" value={deviceState.A_PT} name="A_PT"
                                    onChange={handleValueChange}
                                />
                                <label htmlFor="floatingInput">All Playtime</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-1 text-center pb-2">
                    <button type="button" className={deviceState.TS ? "btn btn-primary btn-warning mb-2 btn-lg" : "btn btn-primary btn-success btn-lg mb-2"}
                        onClick={()=>handleButtonValueChange("TS")}
                    > 
                        <Album />{deviceState.TS ? <Pause/> : <PlayArrow/>}
                    </button>
                    {deviceState.TS == 1 && <LinearProgress color="secondary" />}
                </div>
                <div className="col-md-1 text-center pb-2">
                    <button type="button" className={deviceState.HS ? "btn btn-primary btn-warning mb-2 btn-lg" : "btn btn-primary btn-success btn-lg mb-2"}
                         onClick={()=>handleButtonValueChange("HS")}
                    > 
                        <Campaign/> {deviceState.HS ? <Pause/> : <PlayArrow/> }
                    </button>
                    {deviceState.HS == 1 && <LinearProgress color="secondary" />}
                </div>
                

            </div>


            {/* <LinearProgress  style={{color:"#F00",height: 10, borderRadius: 5, backgroundColor:"#0F0"}} /> */}

            <h6>{ /* JSON.stringify(deviceState) */}</h6>
        </>
    )
}

export default DeviceControl